<template>
  <v-app>
    <v-app-bar
      app
      color="blue darken-4"
      dark
    >
      <div class="d-flex align-center">
        <!--<v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="http://198.71.63.73:30006/file/2023/03/logo-assa-movil-light.png"
          transition="scale-transition"
          width="40"
        />-->

        <a href="/">
          <v-img
            alt="ASSA de México"
            class="shrink"
            contain
            :src="`${path.storage_files}/uploads/logo_assa_movil_light_02a2c37af6.png?updated_at=2023-03-26T06:59:56.020Z`"
            width="150"
          />

        </a>
      </div>

      <v-spacer></v-spacer>

      <v-btn text color="blue-grey--text" class="hidden-sm-and-down" href="mailto:asamblea@assa.mx">
        <v-icon left>mdi-help-circle</v-icon>
        ¿Necesitas ayuda?
      </v-btn>

      <v-btn x-small fab dark class="hidden-md-and-up" color="white blue--text text--darken-2" href="mailto:asamblea@assa.mx">
        <v-icon>mdi-help</v-icon>
      </v-btn>


    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>


    <v-footer
      dark
      padless
      color="red darken-4"
    >
    <div class="blue-grey lighten-5" style="width:100%">
      <v-container>
        <v-row>
          <v-col cols md="4">
            <v-img
              :lazy-src="`${path.storage_files}/uploads/logo_assa_9846541ede.png?updated_at=2023-03-26T06:59:56.238Z`"
              max-height="150"
              max-width="250"
              :src="`${path.storage_files}/uploads/logo_assa_9846541ede.png?updated_at=2023-03-26T06:59:56.238Z`"
            ></v-img>
          </v-col>
          <v-col cols md="4">&nbsp;</v-col>
          <v-col cols md="4">&nbsp;</v-col>
        </v-row>
      </v-container>
    </div>
      <v-container>
        <v-row class="">
          <v-col cols md="12" class="text-center">
            <small>{{ new Date().getFullYear() }} — <strong> Asociacion Sindical de Sobrecargos de Aviacion de Mexico</strong></small>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>


  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
  }),
};
</script>
