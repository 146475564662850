<template>
  <v-container>
    <v-row class="">
      <v-col class="mb-4">
        <h1 class="">
          Aeromar
        </h1>
      </v-col>
    </v-row>


    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >

      <v-row>

        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="data.employeeCode"
            label="Número de Empleado"
            outlined
            clearable
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="data.curp"
            label="CURP"
            outlined
            clearable
            :rules="rules.curp"
          ></v-text-field>
        </v-col>
        

      </v-row>


      <v-row>
        <v-col col md="12" class="dorado rounded mb-10 py-6 px-6">

          <v-btn
            :disabled="!valid"
            x-large
            color="red darken-4"
            dark
            class="float-right ma-1"
            @click="getEmployee(data)"
          >
            Iniciar registro
            <v-icon
              right
              dark
            >
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>


    <v-row>
      <v-col>
        <v-alert
          v-if="alertNoResult"
          border="top"
          color="red lighten-2"
          dark
        >
          Tus datos no coinciden con nuestros registros
        </v-alert>

        <v-alert
          v-if="alertRegister"
          border="top"
          color="green lighten-2"
          dark
        >
          Se ha confirmado tu participación, recibiras un correo electrónico con los datos necesarios para que puedas acceder.
        </v-alert>

        <div v-if="success">
          <v-alert
            v-if="currentEmployee[0].register"
            border="top"
            color="deep-orange darken-2"
            dark
          >
            Ya has confirmado tu participación, recibiras un correo electrónico con los datos necesarios para que puedas acceder.
          </v-alert>
        </div>

      </v-col>
    </v-row>


    <v-row v-if="success">

      <v-col cols md="12">

        <v-simple-table class="datosEmpleado">
          <template v-slot:default>
            <thead class="blue-grey darken-4">
              <tr>
                <th class="text-uppercase text-center" colspan="3"><h2 class="white--text">Datos encontrados</h2></th>
              </tr>
            </thead>
            
            <tbody >
              <tr>
                <td class="" colspan="2">
                  <small class="blue-grey--text">Aerolínea</small>
                  <span class="text-td strong">{{ currentEmployee[0].company.name }}</span>
                </td>
                <td class="">
                  <small class="blue-grey--text">Número de Empleado</small>
                  <span class="text-td strong">{{ currentEmployee[0].employeeCode }}</span>
                </td>
              </tr>
              <tr>
                <td class="">
                  <small class="blue-grey--text">Nombre completo</small>
                  <span class="text-td strong">{{ currentEmployee[0].fullName }}</span>
                </td>
                <td class="">
                  <small class="blue-grey--text">Cargo</small>
                  <span class="text-td strong">{{ !currentEmployee[0].position ? 'N/A' : currentEmployee[0].position.name }}</span>
                </td>
                <td class="">
                  <small class="blue-grey--text">Estatus</small>
                  <span class="text-td strong">{{ !currentEmployee[0].status ? 'N/A' : currentEmployee[0].status.name }}</span>
                </td>
              </tr>
              <tr>
                <td class="" colspan="2">
                  <small class="blue-grey--text">CURP</small>
                  <span class="text-td strong">{{ currentEmployee[0].curp }}</span>
                </td>
                <td class="">
                  <small class="blue-grey--text">Observaciones</small>
                  <span class="text-td strong">{{ currentEmployee[0].observations }}</span>
                </td>
              </tr>
            </tbody>

          </template>
        </v-simple-table>

      </v-col>


      <v-col cols md="12" v-if="!currentEmployee[0].register">


        <v-form
          ref="formRegister"
          v-model="valid"
          lazy-validation
        >

          <p class="lead mt-6">
            Para confirmar tu asistencia de forma virtual o presencial debes completar los siguientes datos
          </p>

          <v-row>

            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="dataRegister.email"
                label="Correo Electrónico"
                outlined
                clearable
                :rules="rules.required"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="dataRegister.phone"
                label="Teléfono"
                outlined
                clearable
                :rules="rules.required"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
            <v-checkbox
              v-if="registers.total <= 150"
              v-model="dataRegister.check"
              :label="`Deseo asistir de forma presencial`"
            ></v-checkbox>
            </v-col>
            

          </v-row>


          <v-row>
            <v-col col md="12" class="dorado rounded mb-10 py-6 px-6">

              <v-btn
                :disabled="!valid"
                x-large
                color="red darken-4"
                dark
                class="float-right ma-1"
                @click="registerComplete()"
              >
                Completar registro
                <v-icon
                  right
                  dark
                >
                  mdi-content-save
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>


      </v-col>

      <v-col cols md="12">
        <pre>{{ registers.total }}</pre>
      </v-col>
    </v-row>



  </v-container>
</template>

<script>
import wsSurveys from "@/services/surveys";

export default {
  name: 'AeromarComponent',
  components: {
  },
  
  
  data: () => ({
    valid: true,
    validRegister: true,
    success: false,
    alertNoResult: false,
    alertRegister: false,
    currentEmployee: [],

    data: {
      employeeCode: '',
      curp: ''
    },
    dataRegister: {
      email: '',
      phone: '',
      check: false
    },

    rules: {
      required: [
        value => !!value || 'Este campo es requerido',
      ],
      curp: [
        value => !!value || 'Este campo es requerido',
        value => /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(value.toUpperCase()) || ' No es una CURP válida',
      ],
    },

    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },

  }),

  mounted() {
    document.title = 'Aeromar | Asambleas | ASSA de México'
    this.loadRegisters();
  },

  computed: {
    jsonSurveyComputed: function () {
      //return this.jsonFormSurvey;
    }
  },

  methods: {


    getEmployee(data) {

      var company = 'aeromar'
      var employeeCode = data.employeeCode
      var curp = data.curp

      wsSurveys.findByEmployeeCodeAndCurp(company,employeeCode,curp).then(
        response => {
          if(response.data.meta.pagination.pageCount === 0){
            this.success = false
            this.alertNoResult = true
          }else{
            this.currentEmployee = response.data.data;
            this.success = true
          }
        },
        error => {
          this.success = false
          this.currentEmployee =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },


    loadRegisters() {
      wsSurveys.getRegisters().then(response => {
          this.registers = response.data.meta.pagination;
        })
        .catch(e => {
          console.log(e);
        });
    },



    registerComplete(){

      var dataStorage = {
        //data: {
          email: this.dataRegister.email,
          phone: this.dataRegister.phone,
          faceToFace: this.dataRegister.check,
          employee: this.currentEmployee[0].id
        //}
      }

      
      if(this.$refs.formRegister.validate() === true){
        
        wsSurveys.createRegister({data:dataStorage}).then(
          response => {
            this.register = response.data.data;
            this.alertRegister = true;
            this.success = false;
          },
          error => {
            this.register =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        )
      }

    },




  },
}


</script>
