<template>
  <v-container>
    <v-row class="">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Asambleas
        </h1>
      </v-col>
    </v-row>


</v-container>
</template>
<script>


export default {
  name: 'SurveyList',
  components: {
  },

  data() {
    return {
      surveys: [],
    }
  },

  mounted() {
    //this.loadSurveys()
  },

  methods: {

  },


}
</script>