import http from '@/services/http-common';

class wsSurveys {
  
  findByEmployeeCodeAndCurp(company,employeeCode,curp) {
    return http.get(`/employees?filters[company][title][$eq]=${company}&filters[employeeCode][$eq]=${employeeCode}&filters[curp][$eqi]=${curp}&populate=deep,2`);
  }
  findByEmployeeCodeAndCurpAmx(company,employeeCode,curp) {
    return http.get(`/amx-employees?filters[company][title][$eq]=${company}&filters[employeeCode][$eq]=${employeeCode}&filters[curp][$eqi]=${curp}&populate=deep,2`);
  }

  getRegisters() {
    return http.get("/registers/?filters[faceToFace][$eq]=true&filters[employee][id][$null]=false");
  }

  getRegistersAmx() {
    return http.get("/amx-registers/?filters[faceToFace][$eq]=true&filters[amx_employee][id][$null]=false");
  }

  createRegister(data) {
    return http.post("/registers/", data);
  }

  createRegisterAmx(data) {
    return http.post("/amx-registers/", data);
  }

  getAnswer(uuid) {
    return http.get(`/slugify/slugs/survey-result/${uuid}`);
  }


  /**
   * Positions/Ctaegorias
   */
   getPositions() {
    return http.get("/positions");
  }


  /**
   * Delete File
   */
  deleteFile(id) {
    return http.delete(`/upload/files/${id}`);
  }


  /**
   * Contratos
   */
  createContract(data) {
    return http.post("/contracts/", data);
  }


}

export default new wsSurveys();