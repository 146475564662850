<template>
  <v-container>
    
    <h1>Entrega de CCT's</h1>
    <router-view></router-view>
    
  </v-container>
</template>
<script>
  //import HelloWorld from '@/components/HelloWorld.vue'

  export default {
    name: 'FormsView',

    components: {
      //HelloWorld,
    },
  }
</script>