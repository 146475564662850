<template>
  <div>
    <h2>Representante</h2>


    <v-row>
      <v-col>
        <v-alert
          v-if="alertNoResult"
          border="top"
          color="red lighten-2"
          dark
        >
          Tus datos no coinciden con nuestros registros
        </v-alert>

        <v-alert
          v-if="alertRegister"
          border="top"
          color="green lighten-2"
          dark
        >
          Registro exitoso, en breve recibiras un correo electrónico confirmando los datos de tu registro.
        </v-alert>

      </v-col>
    </v-row>


    <v-form
      ref="formRegister"
      v-model="valid"
      lazy-validation
    >

      <v-row class="my-4 d-flex justify-center">
        <v-col cols md="4" class="">
          <v-text-field
            :disabled="employeeDataKey"
            v-model="verify.employeeKey"
            label="Clave de Empleado"
            placeholder="Ej. AMX12345"
            outlined
            clearable
            :rules="rules.required"
            prepend-icon="mdi-key-variant"
          ></v-text-field>
        </v-col>
        <v-col col md="4" class="">
          <v-btn
            v-if="!employeeDataKey"
            :disabled="!valid || employeeDataKey"
            x-large
            color="red darken-4"
            dark
            @click="registerStart()"
          >
            Iniciar entrega de CCT
            <v-icon
              right
              dark
            >
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn
            v-if="employeeDataKey"
            :disabled="!employeeDataKey"
            dark
            x-large
            color="blue darken-4"
            @click="employeeDataKey = false"
          >
            Reiniciar
            <v-icon right>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="employeeDataKey" class="my-4 d-flex justify-center">
        <v-col md="8" sm="6" xs="12" class="">
          <v-text-field
            label="Nombre completo"
            value=" JUAN PEREZ PEREZ"
            disabled
            outlined
            prepend-icon="mdi-account"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="6" xs="12" class="">
          <v-text-field
            label="Puesto"
            value="SUPERVISOR A"
            disabled
            outlined
            prepend-icon="mdi-account"
          ></v-text-field>
        </v-col>

        <v-col md="12" class="">&nbsp;</v-col>

        
        <v-col cols md="2" sm="6" class="d-flex justify-center">
          <v-btn
            @click="companyCct = 'mexicana'"
            color="transparent"
            text
          >
            <v-img
              :lazy-src="`${path.storage_files}/uploads/mexicana_f8124c2455.png?updated_at=2023-03-28T02:29:53.321`"
              max-height="125"
              max-width="125"
              class="elevation-5 rounded"
              :src="`${path.storage_files}/uploads/mexicana_f8124c2455.png?updated_at=2023-03-28T02:29:53.321`"
            ></v-img>
        </v-btn>
        </v-col>
        <v-col cols md="2" sm="6" class="d-flex justify-center">
          <v-btn
            @click="companyCct = 'aeromexico'"
            color="transparent"
            text
          >
            <v-img
              :lazy-src="`${path.storage_files}/uploads/aeromexico_5d292d7f85.png?updated_at=2023-03-28T02:25:23.172`"
              max-height="125"
              max-width="125"
              class="elevation-5 rounded"
              :src="`${path.storage_files}/uploads/aeromexico_5d292d7f85.png?updated_at=2023-03-28T02:25:23.172`"
            ></v-img>
        </v-btn>
        </v-col>
        <v-col cols md="2" sm="6" class="d-flex justify-center">
          <v-btn
            @click="companyCct = 'aeromar'"
            color="transparent"
            text
          >
            <v-img
              :lazy-src="`${path.storage_files}/uploads/aeromar_6a9491a065.jpg?updated_at=2023-03-28T02:25:22.990`"
              max-height="125"
              max-width="125"
              class="elevation-5 rounded"
              :src="`${path.storage_files}/uploads/aeromar_6a9491a065.jpg?updated_at=2023-03-28T02:25:22.990`"
            ></v-img>
        </v-btn>
        </v-col>
        <v-col cols md="2" sm="6" class="d-flex justify-center">
          <v-btn
            @click="companyCct = 'q'"
            color="transparent"
            text
          >
            <v-img
              lazy-src="https://placehold.co/125x125/0d47a1/white?text=Q"
              max-height="125"
              max-width="125"
              class="elevation-5 rounded"
              src="https://placehold.co/125x125/0d47a1/white?text=Q"
            ></v-img>
          </v-btn>
        </v-col>

        <v-col md="12" class="">&nbsp;</v-col>


      </v-row>

    </v-form>


    <v-form
      ref="formEmployee"
      v-model="valid"
      lazy-validation
    >


      <v-row v-if="employeeDataKey && companyCct" class="my-15">
        
        <v-col md="12">
          <h1>Registro de agremiados <strong class="text-uppercase">{{ companyCct }}</strong></h1>
        </v-col>

        <v-col md="4" sm="6" xs="12" class="">
          <v-text-field
            v-model="data.email"
            :label="`Correo electrónico ${ companyCct === 'aeromexico' ? 'corporativo' : 'personal' }`"
            :rules="companyCct === 'aeromexico' ? rules.emailAmx : rules.email"
            outlined
            prepend-icon="mdi-at"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="6" xs="12" class="">
          <v-text-field
            v-model="data.employeeKey"
            label="Clave de Empleado"
            placeholder="Ej. AMX12345"
            outlined
            clearable
            :rules="rules.required"
            prepend-icon="mdi-key-variant"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="6" xs="12" class="">
          <v-text-field
            v-model="data.fullName"
            label="Nombre completo"
            value=""
            outlined
            prepend-icon="mdi-account"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="6" xs="12" class="">
          <v-text-field
            v-model="data.rol"
            label="Rol"
            value=""
            outlined
            prepend-icon="mdi-format-list-checks"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="data.position"
            label="Categoría"
            :items="positions"
            clearable
            outlined
            item-text="name"
            item-value="id"
            prepend-icon="mdi-view-list"
          ></v-autocomplete>
        </v-col>
        <v-col md="4" sm="6" xs="12" class="">
          <v-select
            v-model="data.station"
            :items="station"
            label="Estación"
            outlined
            prepend-icon="mdi-airplane-marker"
          ></v-select>
          <v-text-field
            v-if="data.station === 'otro'"
            v-model="data.stationOther"
            label="Otra estación"
            outlined
            prepend-icon="mdi-airplane-plus"
          ></v-text-field>
        </v-col>

        <v-col md="12" class="">


          <v-row>
            <v-col col md="6" sm="6" xs="12" class="">
              <div class="">
                <v-file-input
                  label="Identificación"
                  :rules="rules.upload"
                  @change="selectImage"
                  v-model="document"
                  outlined
                  show-size
                  accept="application/pdf,image/jpeg"
                  ref="formUpload"
                ></v-file-input>
              </div>
            </v-col>
            <v-col col md="6" sm="6" xs="12" class="">
              <div v-if="previewImage">
                <div v-if="currentImage.type === 'image/jpeg' || currentImage.type === 'image/png'">
                  <v-img
                    :lazy-src="previewImage"
                    :src="previewImage"
                    contain
                  >
                    <v-progress-linear
                      v-if="loading === true"
                      indeterminate
                      striped
                      height="100%"
                      value="0"
                      color="green"
                    ></v-progress-linear>
                  </v-img>
                </div>
                <div v-else-if="currentImage.type === 'application/pdf'">
                  <iframe :src="previewImage" style="height:320px;width:100%;"></iframe> 
                </div>
                <div v-else>OTRO Archivo</div>

                <v-alert
                  v-if="alertWarning === true"
                  icon="mdi-account"
                  type="error"
                >
                  El archivo debe ser menos a 2MB
                </v-alert>

                <v-btn
                  v-if="alertWarning === false"
                  :disabled="loaded"
                  class="mx-2 my-2 float-left"
                  dark
                  color="yellow darken-4"
                  @click="onUpload"
                >
                  <v-icon left v-if="loading === false" dark>mdi-upload</v-icon>
                  Subir
                  <v-progress-circular
                    v-if="loading === true"
                    icon
                    text
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-btn>
                <v-btn
                  :disabled="!loaded"
                  class="mx-2 my-2 float-right"
                  dark
                  color="red"
                  @click="deleteImage(data.document)"
                >
                  <v-icon dark>
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col md="12">
              <v-row  class="blue-grey lighten-5 rounded mb-10 py-3 px-5">
                <v-col
                  md="6"
                >
                  <v-checkbox
                    v-model="data.correctData"
                    label="Confirmo que los datos son correctos"
                    :rules="rules.required"
                  ></v-checkbox>
                </v-col>
                <v-col col md="6">
                  <v-btn
                    :disabled="!valid"
                    x-large
                    color="red darken-4"
                    dark
                    class="float-right ma-1"
                    @click="registerComplete()"
                  >
                    Completar registro
                    <v-icon
                      right
                      dark
                    >
                      mdi-content-save
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>


          </v-row>


        </v-col>

      </v-row>    

    </v-form>




  </div>
</template>
<script>
import axios from 'axios';
import wsSurveys from "@/services/surveys";

  export default {
    name: 'FormsComponent',

    components: {
      //HelloWorld,
    },

    data: () => ({
      valid: true,
      currentEmployee: [],
      employeeDataKey: false,
      companyCct:'',
      positions: [],
      contract: [],
      alertNoResult: false,
      alertRegister: false,

      currentImage: undefined,
      previewImage: undefined,
      uploadFile: false,
      alertWarning: false,
      loading: false,
      loaded: false,
      
      document: [],

      data: {
        email:'',
        employeeKey: '',
        fullName: '',
        rol: '',
        position: '',
        station: '',
        stationOther: '',
        document: null,
        correctData: false
      },
      verify: {
        employeeKey: ''
      },

      btnList: {
        q: '',
        aeromar:'',
        aeromexico:'',
        mexico:''
      },

      station: [
        {
          value: 'MEX',
          text: 'MEX',
        },
        {
          value: 'GDL',
          text: 'GDL'
        },
        {
          value: 'MTY',
          text: 'MTY'
        },
        {
          value: 'TIJ',
          text: 'TIJ'
        },
        {
          value: 'MID',
          text: 'MID'
        },
        {
          value: 'Envio',
          text: 'Envío'
        },
        {
          value: 'otro',
          text: 'Otro'
        }
      ],


      
      rules: {
        required: [
          value => !!value || 'Este campo es requerido',
        ],
        curp: [
          value => !!value || 'Este campo es requerido',
          value => /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(value.toUpperCase()) || ' No es una CURP válida',
        ],
        emailAmx: [
          value => !!value || 'Este campo es requerido',
          value => /^[a-z0-9]+@aeromexico.com/.test(value) || 'El correo electrónico no pertenece a Aeroméxico'
        ],
        email: [
          value => !!value || 'Este campo es requerido',
          value => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'El correo electrónico no es válido'
        ],
        upload: [
          value => !value || value.size < 2000000 || '¡El documento debe ser menor a 2 MB!',
        ],
      },

      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },

    }),


    mounted() {
      document.title = 'Entrega de CCTs | ASSA de México'
      this.loadPositions()
    },

    computed: {
      jsonSurveyComputed: function () {
        //return this.jsonFormSurvey;
      }
    },


    methods: {

      registerStart(){
        if(this.$refs.formRegister.validate() === true){
          console.log("🚀 ~ file: Forms.vue:107 ~ registerStart ~ this.$refs.formRegister.validate():", this.$refs.formRegister.validate())
          this.employeeDataKey = true 
        }
      },

      loadPositions() {
        wsSurveys.getPositions().then(response => {
            this.positions = response.data.data;
          })
          .catch(e => {
            console.log(e);
          });
      },

      selectImage(image) {
        this.uploadFile = true;
        this.currentImage = image;
        this.previewImage = URL.createObjectURL(this.currentImage);
        //console.log("🚀 ~ file: organizationData.vue:890 ~ selectImage ~ this.$refs.formUpload.validate()", this.$refs.formUpload.validate())
        if(this.$refs.formUpload.validate() === false){
          this.alertWarning = true
        }else{
          this.alertWarning = false
        }
      },

      clearUpload (){
        this.uploadFile = false;
        this.data.document = null,
        this.document = '',
        this.currentImage = undefined;
        this.previewImage = undefined;
        this.$refs.formRegister.reset()
      },

      onUpload(){
        this.loading = true
        const formData = new FormData()
        formData.append('files', this.currentImage)

        axios.post( this.path.endpoint + "/upload", formData, { headers: {Authorization: 'Bearer ' + process.env.VUE_APP_TOKEN_API} })
          .then((response)=>{
            this.response_image = response.data[0]
            this.data.document = this.response_image.id
            this.loading = false
            this.loaded = true

          }).catch((error)=>{
            this.errorsUpload = error
          })
      },


      deleteImage(id){
        wsSurveys.deleteFile(id).then(
          response => {
            this.removeImage = response.data.data;
            this.clearUpload()
          },
          error => {
            this.removeImage =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        )
      },


      registerComplete(){
        var dataStorage = {
          email:this.data.email,
          employeeKey: this.data.employeeKey,
          fullName: this.data.fullName,
          rol: this.data.rol,
          position: this.data.position,
          station: this.data.station,
          stationOther: this.data.stationOther,
          document: this.data.document,
          correctData: this.data.correctData,
          airline: this.companyCct
        }
        console.log("🚀 ~ file: Forms.vue:593 ~ registerComplete ~ this.data:", this.data)
        
        if(this.$refs.formEmployee.validate() === true){
          
          wsSurveys.createContract({data:dataStorage}).then(
            response => {
              this.contract = response.data.data;
              this.alertRegister = true;
              this.success = false;
              this.employeeDataKey = false
              this.$refs.formRegister.reset()
              this.$refs.formEmployee.reset()
              this.clearUpload()
            },
            error => {
              this.contract =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          )
        }
        

        },



    }

  }
</script>