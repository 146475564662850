import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

import SurveyList from '../views/surveys/List.vue'
//import SurveyView from '../views/surveys/SurveyView.vue'

import MexicanaComponent from '@/components/forms/Mexicana.vue'
import AeromarComponent from '@/components/forms/Aeromar.vue'
import AeromexicoComponent from '@/components/forms/Aeromexico.vue'

import FormsView from '@/views/Forms/FormsView.vue'
import FormsRegisterComponent from '@/components/register/Forms.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/survey',
    name: 'SurveyList',
    component: SurveyList
  },
  {
    path: '/mexicana',
    name: 'Mexicana',
    component: MexicanaComponent
  },
  {
    path: '/aeromar',
    name: 'Aeromar',
    component: AeromarComponent
  },
  {
    path: '/aeromexico',
    name: 'Aeromexico',
    component: AeromexicoComponent
  },


  {
    name: 'Forms',
    path: '/forms/',
    component: FormsView,
    children: [
      {
        name: 'FormsRegister',
        path: 'register',
        component: FormsRegisterComponent
      },
    ]
  },



  
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
