<template>
  <v-container>

    <v-row class="">
      <v-col class="mb-4">
        <h1 class="">
          Asamblea de la ASSA de México
        </h1>
      </v-col>
    </v-row>

    <v-row class="">
      <v-col class="mb-4">
        <h1 class="">
          Salas disponibles
        </h1>
      </v-col>
    </v-row>

    <v-row class="">
      <v-col class="mb-4">
        <h1 class="">
          <a href="https://meet.google.com/zrr-vtpo-jpu">Sala 1</a>
        </h1>
      </v-col>
      <v-col class="mb-4">
        <h1 class="">
          <a href="https://meet.google.com/qrb-ffjg-wyq">Sala 2</a>
        </h1>
      </v-col>
    </v-row>
    <!--
    <v-row class="text-center">
      <v-col class="mt-5">
        <h1 class="display-2">Registro de asambleas de la ASSA de México</h1>
        <h2 class="display--1">Seleciona la empresa a la que perteneces</h2>
      </v-col>
    </v-row>
    
    <v-row class="d-flex justify-center mb-10">
      <v-col cols md="2" sm="6" class="d-flex justify-center">
        <a href="/mexicana">
          <v-img
            :lazy-src="`${path.storage_files}/uploads/mexicana_f8124c2455.png?updated_at=2023-03-28T02:29:53.321`"
            max-height="200"
            max-width="200"
            class="elevation-5 ma-2"
            :src="`${path.storage_files}/uploads/mexicana_f8124c2455.png?updated_at=2023-03-28T02:29:53.321`"
          ></v-img>
        </a>
      </v-col>
      <v-col cols md="2" sm="6" class="d-flex justify-center">
        <a href="/aeromexico">
          <v-img
            :lazy-src="`${path.storage_files}/uploads/aeromexico_5d292d7f85.png?updated_at=2023-03-28T02:25:23.172`"
            max-height="200"
            max-width="200"
            class="elevation-5 ma-2"
            :src="`${path.storage_files}/uploads/aeromexico_5d292d7f85.png?updated_at=2023-03-28T02:25:23.172`"
          ></v-img>
        </a>
      </v-col>
      <v-col cols md="2" sm="6" class="d-flex justify-center">
        <a href="/aeromar">
          <v-img
            :lazy-src="`${path.storage_files}/uploads/aeromar_6a9491a065.jpg?updated_at=2023-03-28T02:25:22.990`"
            max-height="200"
            max-width="200"
            class="elevation-5 ma-2"
            :src="`${path.storage_files}/uploads/aeromar_6a9491a065.jpg?updated_at=2023-03-28T02:25:22.990`"
          ></v-img>
        </a>
      </v-col>

    </v-row>
-->

  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    }),

    mounted() {
      document.title = 'Asambleas | ASSA de México'
    },

  }
</script>
