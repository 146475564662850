<template>
  <div class="home">
    <SurveyList />
  </div>
</template>

<script>
import SurveyList from '@/components/surveys/SurveysList.vue'

export default {
  name: 'Survey',
  components: {
    SurveyList
  }
}
</script>
